<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Tiere</h1>
        <p>
          In unserem Projekt geht es um die Wiederherstellung des Ökosystems und um die Erzeugung von Nahrungsmitteln
          für den Menschen. Über Jahrhunderte sind der Mensch und einige Tierarten eine symbiotische Beziehung
          eingegangen, was dazu geführt hat, dass einige Arten als Nutztiere gelten, während andere Arten Wildtiere
          sind.
        </p>

        <h2>Tiere auf dem Bauernhof</h2>
        <p>
          Auf unserem Land leben derzeit Rinder (Bos taurus), Schweine (Sus scrofa domesticus), Schafe (Ovis aries),
          Hunde (Canis familiaris) und Pferde (Equus ferus caballus). Sie können mehr über die einzelnen Arten erfahren
          und warum es sie hier gibt, wenn Sie den Links auf der linken Seite folgen.
        </p>
        <p v-if="hasListAnimals" class="py-2">
          Einige Tierarten sind Teil unseres Betriebes der Lebensmittel für Menschen produziert. Bitte schauen Sie sich
          den jeweiligen Abschnitt an, um mehr zu erfahren.
        </p>
        <p>
          Es gibt auch ein paar Katzen, die sich in der Nähe der Gebäude oder in gartenähnlichen Bereichen aufhalten, wo
          die Hunde nicht hinkommen.
        </p>

        <h2>Wilde Tiere</h2>
        <p>
          Da wir uns in einem Teil der spanischen Dehesa in einem Gebiet mit wenig menschlichen Aktivitäten befinden,
          werden wir häufig von Wildtieren besucht. Es gibt eine größere Anzahl von Vögeln, deren Menge aufgrund unserer
          Aktivitäten zuzunehmen scheint. Vögel lieben Kuhfladen, da sie darin Fliegenlarven und andere Insekten finden.
        </p>
        <p>
          Ganz in der Nähe befindet sich ein Schutzgebiet, in dem der <a target="_blank"
            href="https://en.wikipedia.org/wiki/Iberian_lynx">Iberische Luchs</a>
          freigelassen wurde. Allerdings haben wir hier bei uns noch keinen gesehen. Andere Tierarten, die wir bisher
          aus der Nähe gesehen haben, sind:
        </p>
        <ul>
          <li>Wildschwein (Sus scrofa)</li>
          <li>Reh (Cervidae)</li>
          <li>Fuchs</li>
          <li>Hase (Oryctolagus cuniculus)</li>
        </ul>
        <p>
          Natürlich gibt es auch die üblichen anderen kleineren Tiere wie Mäuse und Ratten. Es gibt Frösche, Eidechsen
          und wahrscheinlich mehr, als uns zum Zeitpunkt des Schreibens bekannt ist.
        </p>
        <p>
          Einige dieser Tierarten sind Raubtiere und greifen unser Vieh an, wenn es nicht richtig geschützt wird. Wir
          akzeptieren diese Tatsache, da sie Teil der Natur ist, und versuchen, unsere Tiere so gut wie möglich zu
          schützen.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
import { setupFeatureFlags } from '@/library'

export default {
  name: "AnimalsDE",
  components: {
    Sidebar,
  },

  setup () {
    return setupFeatureFlags()
  },

};
</script>
